.support {
  max-width: 300px;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10000;
  transform: translateY(-50px) translateX(30px);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  overflow-y: auto;

  &__show {
    background: white;

    @media (max-width: 767px) {
      max-width: none;
      height: 100vh;
      width: 100vw;
      transform: translateY(0) translateX(0);
      bottom: 0;
      left: 0;
    }
  }

  button {
    width: 150px;
    transition: width 0.25s ease-in-out;
    position: relative;
    order: 2;
    transform-origin: right center;
  }

  button[aria-expanded="true"] {
    border-radius: 0;
    width: 100%;

    &::after {
      display: block;
      width: 12px;
      height: 24px;
      content: ' ';
      position: absolute;
      right: 10px;
      top: 12px;
      background-image: url("/images/icons/subtract.svg");
      background-repeat: no-repeat;
    }
  }

  .collapse {
    order: 1;
    width: 100%;

    .card {
      transition: all .5s ease-in-out;

      form {
        .col-lg-6 {
          width: 100% !important;
        }

        .col-lg-2 {
          width: 100% !important;
        }
      }
    }
  }
}
